import React from "react"
import styled from "styled-components"
import ihk_logo_header_with_text from "../../assets/images/logo/logo-ihk-darmstadt-png-data.svg"
import ihk_icon_kontakt from "../../assets/images/icon/IHK__Kontakt_Icon_blau.svg"
import { layoutStyles } from "../../styles/layoutStyles"
import { Link } from "gatsby"
import { colors } from "../../styles/colors"

const SubHeaderWrapper = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid rgba(95, 98, 102, 0.3);
`

const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${layoutStyles.contentPadding};
  margin: 0 auto;
`
const SubHeaderImage = styled.img`
  max-height: 55px;
  width: auto;
  margin-left: 0.25rem;
`

const StLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${colors.ihk_darkblue};
  font-size: 1.25rem;
  margin-right: 4rem;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;

  :hover {
    transition: color 0.1s ease-in-out;
    color: #2e8533;

    img {
      transition: filter 0.2s ease-in-out;
      filter: invert(34%) sepia(99%) saturate(380%) hue-rotate(74deg)
        brightness(98%) contrast(83%);
    }
  }
  img {
    max-height: 75px;
    margin-bottom: 3px;
  }

  @media only screen and (max-width: 499px) {
    span {
      display: none;
    }
    img {
      min-height: 75px;
    }
    margin-right: 0;
  }
`

const Navigation = ({ setIsContactFormOpen }) => (
  <>
    <SubHeaderWrapper>
      <SubHeader>
        <SubHeaderImage
          src={ihk_logo_header_with_text}
          alt={"IHK - Darmstadt Rhein Main Neckar"}
        />
        <StLink
          to={"#kontakt"}
          onClick={() => {
            setIsContactFormOpen(true)
          }}
        >
          <img src={ihk_icon_kontakt} alt={"Kontakt"} />
          <span>Kontakt</span>
        </StLink>
      </SubHeader>
    </SubHeaderWrapper>
  </>
)

export default Navigation
