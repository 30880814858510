import React from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"
import { layoutStyles } from "../styles/layoutStyles"

import twitterIcon from "../assets/images/icon/impressum/IHK_Impressum_Icon_Twitter.png"
import facebookIcon from "../assets/images/icon/impressum/IHK_Impressum_Icon_Facebook.png"
import instagramIcon from "../assets/images/icon/impressum/IHK_Impressum_Icon_Insta.png"
import locationIcon from "../assets/images/icon/impressum/IHK_Impressum_Icon_Standort.png"

const StFooter = styled.footer`
  background-color: ${colors.ihk_darkblue};
  color: white;
  padding: 2rem 0;
  position: relative;
  z-index: 99;
`
const FooterInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: ${layoutStyles.contentWidth};
  padding: ${layoutStyles.contentPadding};
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  font-family: "Asap Condensed", sans-serif;
`
const RowContainerBottom = styled(RowContainer)`
  margin-bottom: 1rem;
  flex-wrap: wrap-reverse;
  font-family: "Source Sans Pro", sans-serif;
`

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const SocialMediaImage = styled.img`
  width: 65px;
  height: auto;
  margin-right: 1rem;
`
const SocialMediaImageContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
`
const StLink = styled.a`
  margin: 0 0 1rem 0;
  color: white;
  text-decoration: none;

  :hover {
    text-decoration: underline;
    text-underline: white;
  }
`
const StLinkWithIcon = styled(StLink)`
  display: flex;
  align-items: center;
  margin: 0 0 0.25rem 0;
`

const StImageContact = styled.img`
  width: 50px;
  height: auto;
`
const ResponsibilityContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 1rem;

  @media only screen and (max-width: 699px) {
    width: 100%;
  }
`

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`
const FooterColumnMarginRight = styled(FooterColumn)`
  margin-right: 35px;
`

export const Footer = () => {
  return (
    <StFooter id={"kontakt"}>
      <FooterInnerContainer>
        <RowContainer>
          <FooterColumn>
            <SocialMediaContainer>
              Besuchen Sie uns bei:
              <SocialMediaImageContainer>
                <a href={"https://twitter.com/IHKDarmstadt"}>
                  <SocialMediaImage src={twitterIcon} alt={"IHK Twitter"} />
                </a>
                <a href={"https://de-de.facebook.com/IHKDarmstadt"}>
                  <SocialMediaImage src={facebookIcon} alt={"IHK Facebook"} />
                </a>
                <a href={"https://www.instagram.com/zukunft_ihkdarmstadt"}>
                  <SocialMediaImage src={instagramIcon} alt={"IHK Instagram"} />
                </a>
              </SocialMediaImageContainer>
              <StLink
                href={
                  "https://www.darmstadt.ihk.de/internetauftritt/impr-2520832"
                }
              >
                Impressum
              </StLink>
              <StLink
                href={
                  "https://www.darmstadt.ihk.de/internetauftritt/datenschutz-2520830"
                }
              >
                Datenschutzerklärung
              </StLink>
            </SocialMediaContainer>
          </FooterColumn>
          <FooterColumnMarginRight>
            <StLinkWithIcon
              href={
                "https://www.google.de/maps/place/IHK+Darmstadt/@49.8717244,8.637161,15z/data=!4m2!3m1!1s0x0:0x3e8fa4dd94a56ed2?sa=X&ved=0CHMQ_BIwDWoVChMIlZDAsaX2yAIVRB8PCh2tXQr1"
              }
            >
              <StImageContact
                style={{ alignSelf: "flex-start" }}
                src={locationIcon}
                alt={"Standort"}
              />
              IHK Darmstadt <br /> Rhein Main Neckar <br /> Rheinstraße 89{" "}
              <br /> 64295 Darmstadt
            </StLinkWithIcon>
          </FooterColumnMarginRight>
        </RowContainer>
        <RowContainerBottom>
          <ResponsibilityContainer>
            © Industrie- und Handelskammer Darmstadt <br />
            Für Richtigkeit der in dieser Website enthaltenen Angaben können wir
            trotz sorgfältiger Prüfung keine Gewähr übernehmen. Bei den
            angelinkten externen Seiten handelt es sich ausschließlich um fremde
            Inhalte, für die wir keine Haft ung übernehmen und deren Inhalt wir
            uns nicht zu Eigen machen. <br />
            Hinweis: Zur besseren Lesbarkeit verwenden wir die männliche Form.
            Darunter gefasst sind alle Geschlechter.
          </ResponsibilityContainer>
        </RowContainerBottom>
        <StLink href={"https://www.cosee.biz/"}>Site Design & Development by cosee GmbH</StLink>

      </FooterInnerContainer>

    </StFooter>
  )
}
