import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0;
  }
  a {
    text-decoration: none;
  }
`
