import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Navigation from "./navigation"
import { Footer } from "./Footer"
import { GlobalStyle } from "../styles/GlobalStyles"
import "../../static/font.css"

const Layout = ({ children, setIsContactFormOpen }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="/#"]')
  }

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {/*var et_pagename  = "";*/}
          {/*var et_areas = "";*/}
          {/*var et_tval = 0; */}
          {/*var et_tsale = 0; */}
          {/*var et_tonr = ""; */}
          {/*var et_basket = ""; */}
        </script>
        <script
          id="_etLoader"
          type="text/javascript"
          charset="UTF-8"
          data-block-cookies="true"
          data-respect-dnt="true"
          data-secure-code="1bxoQs"
          src="//static.etracker.com/code/e.js"
        ></script>
        <meta charSet="utf-8" />
        <title>IHK - Energeffizienznetzwerk</title>
        <meta name="keywords" content="ihk, Energieeffizienz, ETA-Plus" />
      </Helmet>
      <Navigation setIsContactFormOpen={setIsContactFormOpen} />
      <GlobalStyle />
      <div
        style={
          {
            // margin: '0 auto',
            // maxWidth: '1000px',
            // padding: '1em 1.0875rem 1.45rem',
          }
        }
      >
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
